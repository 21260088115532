<template>
  <v-row>
    <v-col cols="12" :lg="secondary ? 12 : 6">
      <v-switch :label="$t('active')" v-model="obBranch.active"></v-switch>
    </v-col>

    <v-col cols="12" lg="6" v-if="!secondary">
      <v-switch
        :label="$t('branch.default')"
        v-model="obBranch.is_default"
      ></v-switch>
    </v-col>

    <firm-form v-model="obFirm" all-fields hide-doc hide-legal-name />
  </v-row>
</template>

<script lang="ts">
import type { Branch, FirmData } from "@planetadeleste/vue-mc-gw";
import { Vue, Component, VModel, Prop } from "vue-property-decorator";
import FirmForm from "@/modules/companies/components/FirmForm.vue";

@Component({
  components: { FirmForm },
})
export default class BranchSettings extends Vue {
  @VModel({ type: Object, default: () => ({}) }) obBranch!: Branch;
  @Prop(Boolean) readonly secondary!: boolean;

  get obFirm() {
    return this.obBranch.firm || {};
  }

  set obFirm(obData: Partial<FirmData>) {
    this.obBranch.set("firm", obData);
  }
}
</script>
